









import Vue from "vue";

import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnFrPackage from '@zxcvbn-ts/language-fr'

const options = {
  translations: zxcvbnFrPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnFrPackage.dictionary,
  },
}

zxcvbnOptions.setOptions(options)

const colors = [ "red", "#ffd801", "orange", "#3bce08", "#3bce08" ];
const msgs = [ "Très faible", "Faible", "Moyen", "Fort", "Très fort" ];

export default Vue.extend({
    props: ['passwd'],
    computed: {
        cc() {
            const analysis = zxcvbn(this.passwd);
            const level = analysis.score;
            return { width: ((level + 1) * 60) + "px", color: colors[level], msg: msgs[level], feedback: analysis.feedback }
        },
    },
});
